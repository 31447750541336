:root {
  --primary: #1f3e72;
  --secondary: rgba(255, 255, 255, 0.78);
  --black: #131110;
  --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --blue: #4066ff;
  --lightBlue: #eeeeff;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    background-color: var(--black);
}
.hero-container{
    justify-content: space-around;
    align-items: flex-end;

}
/* right side */
.hero-left{
    gap: 3rem;
}

.hero-title{
    position: relative;
    z-index: 1;

}
.hero-title>h1{
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 4rem;
    
}
.orenge-circle{
    height: 4rem;
    width: 4rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 28%;
    top: -10%;
    z-index: -1;
}
.search-bar{
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120,120,120,0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
  
}
.search-bar> input{
    border: none;
    outline: none;
}
.stats{
    width: 100%;
    justify-content: space-between;
}
.stat>:nth-child(1){
    font-size: 2rem;

}
.stat>:nth-child(1)>:last-child{
    color: orange;
}
/* right side */
.image-container{
    width: 30rem;
    height: 35rem; 
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255,255,255,0.13);
}
.image-container>img{
     width: 100%;
     height: 100%;
}
@media (max-width:640px){
    .hero-container{
        margin-top: 2rem;
    }
    .orenge-circle{
        height: 3rem;
        width: 3rem;
        /* right: 25%;
        top: -8%; */
    }
.search-bar{
 

 
    
    flex-direction: row;
    row-gap: 1rem;
   justify-content: center; 
    align-items: center;
    /* flex-wrap: wrap; */
    flex-wrap: nowrap;
}
.search-bar> input{
   width: 90%;
}
    .hero-title>h1 {
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    .image-container{
        width: 95%;
        height: 25rem;
    }
    .stats{
        justify-content: center;
        gap: 1.5rem;
    }
    .hero-right{
        width: 100%;
    }
    .stat>:nth-child(1){
        font-size: 1.5rem;
    }
    .stat>:nth-child(2){
        font-size: 0.8rem;
    }
    
}
@media (max-width:400px){
    .orenge-circle{
        height: 2.4rem;
        width: 2.5rem;
        right: 25%;
        top: -8%;
    }
}
