@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&family=Whisper&display=swap");
.App{
  position: relative;
  overflow-x: clip;
}

.App>:nth-child(1)
{
  background: var(--black);
  position: relative;
}
.white-gradient{
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: rgba(255, 255,255 , 0.522);
  filter: blur(100px);
  border-radius: 100px;
  z-index: 1;
}

